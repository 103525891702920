import React from 'react';
import styled from 'styled-components';

import Cta from './cta';
import Container from './container';
import Column from './column';
import Icon from './icon';
import Row from './row';

import logo from '../assets/icons/logo.svg';

const Hero = styled(Container)`
  display: flex;
  height: 100vh;
  text-align: center;

  svg {
    width: 100%;
    max-width: 300px;
    margin: 30px auto;
  }
`;

// const Separator = styled.div`
//   width: 240px;
//   height: 2px;
//   margin: 0 auto 32px auto;
//   background: ${({ theme }) => theme.colors.primary};
// `;

export default () => (
  <Hero>
    <Row align="center">
      <Column max="true">
        <Icon icon={logo} fill="#ffffff" />
        <Cta to="#kup-teraz" color="bg" style={{ display: "inline-block" }}>kup teraz</Cta>
      </Column>
    </Row>
  </Hero>
);
