import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import Column from '../components/column';
import Container from '../components/container';
import Cta from '../components/cta';
import Heading from '../components/heading';
import HomeHero from '../components/home-hero';
import Layout from '../components/layout';
import Row from '../components/row';
import { Section, SectionColored } from '../components/section';

const BgImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
  background-color: ${p => p.theme.colors.primary};
`;

const ImageSection = styled(Section)`
  position: relative;
  text-align: center;
  /* background: #e8e7e5; */
  overflow: hidden;
`;

const SectionImg = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 600px;
  height: 754px;
  opacity: .1;

  ${up('lg')} {
    opacity: 1;
  }
`;

// const Sign = styled(Heading)`
//   color: ${p => p.theme.colors.fourth};
// `;

const Price = styled.span`
  display: block;
  margin-bottom: 32px;
  font-size: 64px;
  letter-spacing: 0.05em;

  small {
    display: block;
    font-size: 18px;
  }
`;

const Separator = styled.div`
  width: 240px;
  height: 2px;
  margin: 0 auto 32px auto;
  background: ${({ theme }) => theme.colors.primary};
`;

const ImageColumn = styled(Column)`
  text-align: center;
  margin: 15px 0;

  .gatsby-image-wrapper {
    background-color: ${({ theme }) => theme.colors.fifth};
  }

  ${up('md')} {
    width: 40%;
  }

  ${up('md')} {
    .small {
      width: 50%;
      margin: 0 auto;
    }
  }
`;

const ImageRow = styled(Row)`
  /* ${up('md')} {
    margin-top: -75px;
  }

  ${up('xl')} {
    margin-top: -150px;
  } */
`;

export default ({ data }) => {
  return (
    <Layout>
      <BgImg>
        <Img
          fluid={data.hero.childImageSharp.fluid}
          style={{ height: "100%" }}
        />
      </BgImg>
      <HomeHero />
      <ImageSection>
        <SectionImg>
          <Img fluid={data.image13.childImageSharp.fluid} />
        </SectionImg>
        <Container fluid>
          <Row>
            <Column offset="5">
              <Heading as="span" color="primary">
                zupełnie wyjątkowy
                <br />
                album dzieciństwa
              </Heading>
              <p>
                Mocno wierzymy, że pisanie jest ważne. Że jest niezjednanym
                przekaźnikiem naszych uniesień. Wierzymy też, że rozmowa
                z dzieckiem o tym, co wniosło do naszego życia, jest jednym
                z najczulszych momentów naszej wspólnej drogi i być może
                jednym z najważniejszych prezentów jakie może od nas dostać.
                A ponad wszystko wierzymy, że nie ma piękniejszej niedzieli
                niż ciasto z truskawkami i historie z pamiętnika czytane całą
                rodziną.
              </p>
              <Cta to="#kup-teraz">kup teraz</Cta>
            </Column>
          </Row>
        </Container>
      </ImageSection>
      <Section style={{ textAlign: "center" }}>
        <Container>
          <Row>
            <Column max="true">
              <p>
                "Mamy wspomnienia" to pamiętnik, który powstał z założenia, że
                mama jest tak samo ważna jak jej maleństwo. Chciałyśmy,
                żeby opowieść o czasie, który przyniósł mały człowiek, była
                opowiedziana z czułością i uważnością na wszystko to, co
                się zatrzęsło wraz z jego przyjściem.
                <br />
                <br />
                Dlatego właśnie w naszym pamiętniku każda mama znajdzie
                miejsce na swoje westchnienia, wzruszenia i przełomy, które
                pokonuje od pierwszego dnia nowego życia.
                <br />
                <br />
                To opowieść dedykowana najdroższej, małej istocie spleciona
                z najważniejszych wydarzeń i emocji które grały
                w jej najbliższych. To wreszcie książka, która ma za zadanie
                zachować historię.
                <br />
                <br />
                Dlatego, piszcie! - To naprawdę ważne.
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <SectionColored line>
        <Container>
          <Row style={{ alignItems: "flex-end" }}>
            <Column>
              <p>
                Pamiętnik "mamy wspomnienia" podzielony jest na pięć głównych
                rozdziałów.
              </p>
              <ol>
                <li>
                  <p>
                    <strong>MAMA</strong> - poświęcony mamie
                    i jej doświadczeniom/emocjom, które towarzyszyły jej
                    od pierwszych chwil bycia mamą (ciąża, pierwsze wieści,
                    przygotowania, rozmowy, poród, pierwsze czułości
                    wymieniane z maluszkiem na świecie, perspektywa nowego
                    domu)
                  </p>
                </li>
                <li>
                  <p>
                    <strong>DZIECKO</strong> - o maluszku. O tym jak się
                    rozwija, jakie robi postępy, kiedy zaczyna podnosić
                    główkę, gaworzyć, uśmiechać się, raczkować itp. W tym
                    rozdziale znajduje się też kalendarium z 3-,6-,9-ciu
                    miesięcy i roczku z zaprojektowanymi kartami na spisywanie
                    osiągnięć malucha w kolejnych miesiącach i wspomnień
                    z historią rodziny.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>ŻYCIE</strong> - to rozdział zaplanowany
                    z potrzeby zachowania dowodów z życia dziecka. jest
                    tu miejsce na odbicie stópek i rączek maleństwa, koperta
                    na pierwsze ścięte włoski, mapa zębów, strona "pierwszych
                    spotkań" (w teatrze / kinie / zoo itp.)
                  </p>
                </li>
                <li>
                  <p>
                    <strong>ŚWIAT </strong>- to rozdział poświęcony odrobinę
                    starszemu maluszkowi. To miejsce na żłobkowo-przedszkolne
                    historie, na sukcesy, opisy pierwszych zażyłości
                    towarzysko-rozrywkowych, takie jak pierwsze przyjaźnie czy
                     ulubione zabawy.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>MYŚLI</strong> - to ostatni rozdział poświęcony
                    tytułowym wspomnieniom mamy, czyli najbardziej
                    pamiętnikowa część, składająca się z pustych kart
                    na wszystkie zapiski, które mama chciałaby zachować
                    w pamięci na zawsze.
                  </p>
                </li>
              </ol>
            </Column>
            <Column>
              <p>
                opracowanie: Barbara Borkowska i Aleksandra Paruzel
                <br />
                korekta: Maja Bieńkowska
                <br />
                ilustracje: Joanna Okoniewska
                <br />
                skład: Maja Tybel / Yasne Lab
                <br />
                druk: Greg Print Gliwice
                <br />
                <br />
                wydanie I marzec, 2019; nakład 500 sztuk
                <br />
                copyright: czuło 2019 - wszelkie prawa zastrzeżone
                <br />
                ISBN: 978-83-953606-0-2
                <br />
                <br />
                okładka: płócienna tkanina introligatorska z tłoczonymi
                i złoconymi literami
                <br />
                ilość stron: 144
                <br />
                format: 25 x 16,5 cm
                <br />
                papier: Olin Natural White 120g
              </p>
            </Column>
          </Row>
        </Container>
      </SectionColored>
      <ImageSection className="npt">
        <Container fluid>
          <ImageRow justify="space-around" align="center">
            <ImageColumn>
              <Img fluid={data.image2.childImageSharp.fluid} />
            </ImageColumn>
            <ImageColumn>
              <Img fluid={data.image3.childImageSharp.fluid} />
            </ImageColumn>
            <ImageColumn>
              <Img fluid={data.image4.childImageSharp.fluid} />
            </ImageColumn>
          </ImageRow>
          <ImageRow justify="space-around" align="center">
            <ImageColumn>
              <Img fluid={data.image5.childImageSharp.fluid} />
            </ImageColumn>
            <ImageColumn>
              <Img fluid={data.image6.childImageSharp.fluid} />
            </ImageColumn>
            <ImageColumn>
              <Img fluid={data.image7.childImageSharp.fluid} />
            </ImageColumn>
          </ImageRow>
          <ImageRow justify="space-around" align="center">
            <ImageColumn>
              <Img fluid={data.image8.childImageSharp.fluid} />
            </ImageColumn>
            <ImageColumn>
              <Img fluid={data.image9.childImageSharp.fluid} />
            </ImageColumn>
            <ImageColumn>
              <Img fluid={data.image10.childImageSharp.fluid} />
            </ImageColumn>
          </ImageRow>
          <ImageRow justify="space-around" align="center">
            <ImageColumn>
              <Img fluid={data.image11.childImageSharp.fluid} />
            </ImageColumn>
            <ImageColumn>
              <Img fluid={data.image12.childImageSharp.fluid} />
            </ImageColumn>
            <ImageColumn>
              <Img fluid={data.image13.childImageSharp.fluid} />
            </ImageColumn>
          </ImageRow>
          <ImageRow justify="space-around" align="center">
            <ImageColumn>
              <Img fluid={data.image14.childImageSharp.fluid} />
            </ImageColumn>
            <ImageColumn>
              <Img fluid={data.image15.childImageSharp.fluid} />
            </ImageColumn>
            <ImageColumn>
              <Img fluid={data.image16.childImageSharp.fluid} />
            </ImageColumn>
          </ImageRow>
          <ImageRow justify="space-around" align="center">
            <ImageColumn>
              <Img fluid={data.image17.childImageSharp.fluid} />
            </ImageColumn>
            <ImageColumn>
              <Img fluid={data.image18.childImageSharp.fluid} />
            </ImageColumn>
            <ImageColumn>
              <Img fluid={data.image19.childImageSharp.fluid} />
            </ImageColumn>
          </ImageRow>
          <ImageRow justify="space-around" align="center">
            <ImageColumn>
              <Img fluid={data.image20.childImageSharp.fluid} />
            </ImageColumn>
            <ImageColumn>
              <Img fluid={data.image21.childImageSharp.fluid} />
            </ImageColumn>
            <ImageColumn>
              <Img fluid={data.image22.childImageSharp.fluid} />
            </ImageColumn>
          </ImageRow>
        </Container>
      </ImageSection>
      <SectionColored>
        <Container id="kup-teraz">
          <Row style={{ textAlign: "center" }}>
            <Column max="true">
              <Heading as="span">
                mamy
                <br />
                wspomnienia
              </Heading>
              <Separator />
              <Price>
                149 zł
                <small>+ koszty wysyłki</small>
              </Price>
              <Cta to="/koszyk" onClick={() => {
                if (window && typeof fbq === 'function') {
                  window.fbq('track', 'AddToCart', {
                    content_name: 'Mamu wspomienia',
                    content_type: 'product',
                    value: 149,
                    currency: 'PLN' 
                  });
                }
              }}>kup teraz</Cta>
            </Column>
          </Row>
        </Container>
      </SectionColored>
    </Layout>
  );
}

export const query = graphql`
         query {
           hero: file(relativePath: { eq: "hero.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 1600, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image1: file(relativePath: { eq: "mamy-wspomnienia-1.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image2: file(relativePath: { eq: "mamy-wspomnienia-2.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image3: file(relativePath: { eq: "mamy-wspomnienia-3.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image4: file(relativePath: { eq: "mamy-wspomnienia-4.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image5: file(relativePath: { eq: "mamy-wspomnienia-5.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image6: file(relativePath: { eq: "mamy-wspomnienia-6.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image7: file(relativePath: { eq: "mamy-wspomnienia-7.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image8: file(relativePath: { eq: "mamy-wspomnienia-8.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image9: file(relativePath: { eq: "mamy-wspomnienia-9.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image10: file(relativePath: { eq: "mamy-wspomnienia-10.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image11: file(relativePath: { eq: "mamy-wspomnienia-11.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image12: file(relativePath: { eq: "mamy-wspomnienia-12.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image13: file(relativePath: { eq: "mamy-wspomnienia-13.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image14: file(relativePath: { eq: "mamy-wspomnienia-14.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image15: file(relativePath: { eq: "mamy-wspomnienia-15.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image16: file(relativePath: { eq: "mamy-wspomnienia-16.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image17: file(relativePath: { eq: "mamy-wspomnienia-17.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image18: file(relativePath: { eq: "mamy-wspomnienia-18.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image19: file(relativePath: { eq: "mamy-wspomnienia-19.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image20: file(relativePath: { eq: "mamy-wspomnienia-20.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image21: file(relativePath: { eq: "mamy-wspomnienia-21.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
           }
           image22: file(relativePath: { eq: "mamy-wspomnienia-22.jpg" }) {
             childImageSharp {
               fluid(maxWidth: 500, quality: 100) {
                 ...GatsbyImageSharpFluid_withWebp_noBase64
               }
             }
            }
          }
       `;