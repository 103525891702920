import styled from 'styled-components';
import Link from './link';

const Cta = styled(Link)`
  display: inline;
  padding: 20px;
  margin: 0 auto;
  color: ${({ theme, color }) => color ? theme.colors[color] : theme.colors.primary};
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-decoration: none;
  border-bottom: 1px solid ${({ theme, color }) => color ? theme.colors[color] : theme.colors.primary};
  transition: padding .2s;
  cursor: pointer;

  &:hover, &:focus {
    outline: none;
    padding: 20px 40px; 
  }
`;

export default Cta;
